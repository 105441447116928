module.exports = [
  {
    id: 1,
    name: 'draft',
    up: 2,
    delete: true
  },
  {
    id: 2,
    name: '1st review',
    up: 3,
    down: 1,
    edit: true
  },
  {
    id: 3,
    name: '2nd review',
    up: 4,
    down: 2,
    edit: true
  },
  {
    id: 4,
    name: 'approved',
    down: 3
  }
]
